
<template>
  <portal
    v-if="isMobileOrTablet"
    to="payment-method"
  >
    <div
      v-show="canMakePayment"
      class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-py-3 tw-px-5 tw-bg-white tw-shad"
      style="box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);"
    >
      <div
        id="payment-request-button"
        ref="paymentRequestButton"
      ></div>
    </div>
  </portal>
  <div
    v-else
    id="payment-request-button"
    ref="paymentRequestButton"
  ></div>
</template>

<script>
import analyticsMixin from '@/mixins/analytics';
import deviceWidthMixin from '@/mixins/deviceWidthMixin';
import { waitCallbackTruthy } from '@/utils/waitCallbackTruthy';

export default {
  mixins: [deviceWidthMixin, analyticsMixin],
  props: {
    totalPrice: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {
      pk: process.env.VUE_APP_STRIPE_CLIENT_KEYS,
      canMakePayment: false,
    }
  },
  computed: {
    isMobileOrTablet() {
      return this.deviceWidth <= 991;
    }
  },
  async mounted() {
    await waitCallbackTruthy(() => this.totalPrice !== null)
    this.loadPaymentRequest();
  },
  methods: {
    loadPaymentRequest() {
      const stripe = Stripe(this.pk);
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: this.totalPrice * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [{
          id: 'usps',
          label: 'USPS Priority',
          detail: 'USPS Priority',
          amount: 0,
        }],
      });

      const elements = stripe.elements();
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
      });
      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then(async result => {
        this.canMakePayment =
          result != null &&
          (result?.applePay || result?.googlePay);
        if (result) {
          prButton.mount('#payment-request-button');

          this.$store.dispatch('cro/trackSegmentCRO', {
            event: 'shippingStartApplePay',
          });

          this.$_analytics_track('Test Start', {
            Name: 'shippingStartApplePay',
          });
        } else {
          document.getElementById('payment-request-button').style.display =
            'none';
        }
      });
      paymentRequest.on('paymentmethod', ev => {
        this.$emit('success', ev)
        ev.complete('success');
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>