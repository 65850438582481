<template>
  <div class="billing-input">
    <!-- payment option -->
    <!-- 'visible' tag is the responsible for showing the active accordio -->
    <!-- please refer to this link https://bootstrap-vue.org/docs/components/collapse -->

    <div class="accordion" role="tablist">
      <b-card no-body class="mb-2">
        <!-- <div class="payment-option ml-4 mr-3">
          <label for="discountcode">Discount Code</label>
          <b-input-group>
            <b-form-input
              v-model="discountcode"
              :formatter="discountFormatter"
              name="discountcode"
            ></b-form-input>
            <template #append>
              <button class="discount-btn" @click="applyCode">
                Apply Code
              </button>
            </template>
          </b-input-group>
          <small v-if="body.hasOwnProperty('couponid')"
            ><a href="javascript:void(0)" @click="clearDiscount"
              >Remove discount code</a
            ></small
          >
        </div> -->
        <div
          @click="isCreditOrDebitVisible = !isCreditOrDebitVisible"
          :aria-expanded="isCreditOrDebitVisible ? 'true' : 'false'"
          aria-controls="accordion-1"
          header-tag="header"
          role="tab"
          class="d-flex payment-option"
        >
          <div>
            <span class="when-opened" v-if="isCreditOrDebitVisible">
              <b-avatar variant="tertiary" size="22px">
                <b-icon
                  icon="check"
                  font-scale="1.2"
                  style="color: #cdfcb1"
                ></b-icon>
              </b-avatar>
            </span>
            <span class="when-closed" v-else>
              <b-avatar
                style="background-color: #e0e0e0"
                icon="chevron-up"
                size="22px"
              >
                <b-icon
                  icon="circle-fill"
                  font-scale="1.2"
                  style="color: #e0e0e0"
                ></b-icon>
              </b-avatar>
            </span>
          </div>
          <div class="payment-title" style="flex-grow: 1">
            Credit or Debit Card
          </div>
          <div>
            <img src="@/assets/images/payment-methods/jcb.svg" width="20px" />
            <img src="@/assets/images/payment-methods/visa.svg" width="20px" />
            <img
              src="@/assets/images/payment-methods/mastercard.svg"
              width="20px"
            />
          </div>
        </div>
        <b-collapse
          id="accordion-1"
          accordion="my-accordion"
          v-model="isCreditOrDebitVisible"
          role="tabpanel"
        >
          <b-card-body>
            <b-row>
              <b-col>
                <div ref="card" class="form-control m-2">
                  <!-- A Stripe Element will be inserted here. -->
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-4" v-show="canMakePayment">
        <div
          @click="isPayWithVisible = !isPayWithVisible"
          :aria-expanded="isPayWithVisible ? 'true' : 'false'"
          aria-controls="accordion-2"
          header-tag="header"
          role="tab"
          class="d-flex payment-option"
        >
          <div>
            <span class="when-opened" v-if="isPayWithVisible">
              <b-avatar variant="tertiary" size="22px">
                <b-icon
                  icon="check"
                  font-scale="1.2"
                  style="color: #cdfcb1"
                ></b-icon>
              </b-avatar>
            </span>
            <span class="when-closed" v-else>
              <b-avatar
                style="background-color: #e0e0e0"
                icon="chevron-up"
                size="22px"
              >
                <b-icon
                  icon="circle-fill"
                  font-scale="1.2"
                  style="color: #e0e0e0"
                ></b-icon>
              </b-avatar>
            </span>
          </div>
          <div class="payment-title" style="flex-grow: 1">Pay with</div>
          <img
            src="@/assets/images/payment-methods/apple-pay.svg"
            width="20px"
          />
          <img
            src="@/assets/images/payment-methods/google-pay.svg"
            width="20px"
          />
        </div>
        <b-collapse
          v-model="isPayWithVisible"
          id="accordion-2"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-row>
              <b-col>
                <div
                  ref="paymentRequestButton"
                  id="payment-request-button"
                ></div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <b-row>
      <b-col cols="12">
        <b-form-checkbox
          v-model="sameaddress"
          class="mt-3 mb-3 checkbox-label"
          value="true"
          unchecked-value="false"
        >
          My billing information is the same as my shipping information
        </b-form-checkbox>
        <BillingAddress
          v-if="sameaddress != 'true'"
          class="mt-5"
          ref="addressComponent"
        />
        <b-button
          pill
          block
          variant="info"
          class="mt-5 slight-rounded"
          @click="checkAnswers({ paymentmethodid: null })"
          :disabled="disabled"
          >Submit</b-button
        >
        <div class="checkout-agreement mt-3">
          <p>
            By adding a payment method and clicking "Submit," you're agreeing to
            opt into a subscription with Winona. However, you won't be charged
            unless your physician prescribes a treatment plan. If prescribed,
            your payment method will be charged at that time for the cost of
            your prescription(s). Your subscription will renew every month or
            every 3 months, depending on your preference. Please note you can
            cancel your subscription and stop receiving treatment at any time,
            by contacting the Patient Care Team.
          </p>
          <!-- <p class="input-label md mb-0">
            Important. By clicking 'Submit' you agree that:
          </p>
          <ul>
            <li
              class="input-label mb-0"
              v-for="(item, i) in footerarray"
              :key="`fruit-${i}`"
            >
              {{ item }}
            </li>
          </ul> -->

          <!-- <ul> -->
          <!-- <li v-if="body.hasOwnProperty('desiredplan')">If prescribed for </li>
            <li class="input-label mb-0" v-if="body.hasOwnProperty('desiredaddon')">You are purchasing the add-ons listed above and will be charged ${{ total }} now for your shipment.</li>  -->
          <!-- <li class="input-label mb-0">If prescribed for Estradiol Pills, you are purchasing a continuous subscription and will be charged $606 for your first 3 months supply and $404 every 3 months thereafter until you cancel or your prescription expires.</li>
            <li class="input-label mb-0">You can cancel your subscription(s) at any time by contacting our customer care team.</li> -->
          <!-- </ul> -->
        </div>
      </b-col>
      <b-col cols="12" class="mt-5 text-center">
        <b-icon icon="lock-fill"></b-icon> 256-BIT TLS SECURITY
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { StripeService } from "@/services/stripe.service";
import BillingAddress from "@/components/onboarding/checkout/BillingAddress";
import { LookUpService } from "@/services/lookup.service";
import { OnboardingService } from "@/services/onboarding.service";

export default {
  components: {
    BillingAddress,
  },
  props: {
    body: {
      type: Object,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pk: process.env.VUE_APP_STRIPE_CLIENT_KEYS,
      stripe: undefined,
      card: undefined,
      discountcode: "",
      sameaddress: "true",
      loading: false,
      states: [],
      total: 0,
      footerarray: [],
      disabled: false,
      isCreditOrDebitVisible: true,
      isPayWithVisible: false,
      canMakePayment: false,
    };
  },
  watch: {
    isCreditOrDebitVisible(val, prevVal) {
      if (this.isPayWithVisible === false && prevVal === true) {
        this.isCreditOrDebitVisible = true;
      }
    },
    isPayWithVisible(val, prevVal) {
      if (this.isCreditOrDebitVisible === false && prevVal === true) {
        this.isPayWithVisible = true;
      }

      if (val) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    totalPrice() {
      this.loadPaymentRequest();
    },
  },
  // ? Will be restored at a later point.
  // computed : {
  //   desiredplan () {
  //     let desiredplan;
  //     if(this.body.hasOwnProperty('desiredplan')) {
  //       if(this.body.desiredplan.length >= 3) {
  //         for(let index in this.body.desiredplan) {

  //         }
  //       }
  //     } else {
  //       desiredplan = '';
  //     }
  //     return desiredplan;
  //   }
  // },
  methods: {
    discountFormatter(val) {
      return val.toUpperCase();
    },
    loadStripe() {
      this.stripe = Stripe(this.pk);
      this.card = this.stripe.elements().create("card", {
        hidePostalCode: true,
      });
      this.card.mount(this.$refs.card);
    },
    async loadStates() {
      const { data } = await LookUpService.getLookUp("state");
      this.states = data;
      // this.states = data.filter(state => state.hasOwnProperty('supported'));
    },
    loadPaymentRequest() {
      console.log(this.totalPrice);
      let stripe = Stripe(this.pk);
      let paymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: this.totalPrice * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      var elements = stripe.elements();
      var prButton = elements.create("paymentRequestButton", {
        paymentRequest: paymentRequest,
      });
      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then((result) => {
        this.canMakePayment =
          result != null &&
          (result.hasOwnProperty("applePay") ||
            result.hasOwnProperty("googlePay"));
        // this.canMakePayment = result.applePay || result.googlePay;
        if (result) {
          prButton.mount("#payment-request-button");
        } else {
          document.getElementById("payment-request-button").style.display =
            "none";
        }
      });
      paymentRequest.on("paymentmethod", (ev) => {
        let paymentmethodid = ev.paymentMethod.id;
        this.checkAnswers({ paymentmethodid });
        ev.complete("success");
      });
    },
    async applyCode() {
      this.$emit("hideAlert");
      if (this.discountcode == "") {
        this.$emit("showAlert", "Please input a discount code.");
        return;
      } else {
        try {
          const res = await OnboardingService.applyCoupon({
            couponid: this.discountcode,
          });
          this.$emit("update-onboarding");
        } catch (err) {
          this.$emit("showAlert", err.message);
        }
      }
    },
    async clearDiscount() {
      const removeCoupon = await OnboardingService.removeCoupon();
      this.$emit("update-onboarding");
    },
    async checkAnswers({ paymentmethodid }) {
      this.disabled = true;
      let formattedAnswer;
      // * Pre-formats answer
      if (this.sameaddress == "true") {
        formattedAnswer = {
          source: "",
          sameaddress: true,
        };
      } else {
        const billingaddress = {
          ...this.$refs.addressComponent.billingaddress,
        };

        if (
          !billingaddress.address1 ||
          !billingaddress.city ||
          !billingaddress.state ||
          !billingaddress.zipcode ||
          !billingaddress.phoneno
        ) {
          this.$emit(
            "showAlert",
            "Please complete the billing address fields."
          );
          this.disabled = false;
          return;
        }

        formattedAnswer = {
          source: "",
          sameaddress: false,
          billingaddress: billingaddress,
        };
        // if(this.states[0].name != formattedAnswer.billingaddress.state) {
        //   this.$emit('showAlert', 'The state you have selected for the billing address is not yet supported.');
        //   return;
        // }
        if (formattedAnswer.billingaddress.address2 == null) {
          delete formattedAnswer.billingaddress["address2"];
        }

        formattedAnswer.billingaddress.state = this.states.filter(
          (state) =>
            state.name == this.$refs.addressComponent.billingaddress.state
        )[0].id;
      }

      if (paymentmethodid) {
        delete formattedAnswer.source;
        formattedAnswer.paymentmethod = paymentmethodid;
      }

      try {
        if (formattedAnswer.paymentmethod) {
          this.$emit("nextStep", formattedAnswer, this.total);
        } else {
          this.$emit("hideAlert");
          const token = await this.stripe.createToken(this.card);
          if (token.error) {
            this.$emit("showAlert", token.error.message);
            this.disabled = false;
            return;
          }
          formattedAnswer.source = token.token.id;
          this.$emit("nextStep", formattedAnswer, this.total);
          this.loading = true;
        }
      } catch (err) {
        this.loadPaymentRequest();
        this.$emit("showAlert", err);
        this.disabled = false;
        this.loading = false;
      } finally {
        this.disabled = false;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadStripe();
    this.loadStates();
    this.loadPaymentRequest();

    if (this.body.hasOwnProperty("desiredplan")) {
      for (let plan of this.body.desiredplan) {
        //console.log("plan", plan)
        let subscription = "";
        if (plan.pricingdetail.type == "recurring") {
          if (plan.pricingdetail.recurring.interval_count == "90") {
            subscription = "3 months";
          } else if (plan.pricingdetail.recurring.interval_count == "30") {
            subscription = "monthly";
          }
        }

        let notes = `If prescribed ${plan.name}, you are enrolling in a ${subscription} subscription for $${plan.price}.`;
        this.footerarray.push(notes);
      }
      this.footerarray.push("You can cancel your subscription(s) at any time.");
    }
    if (this.body.hasOwnProperty("desiredaddon")) {
      for (let addon of this.body.desiredaddon) {
        this.total += addon.price;
        this.totalPrice += addon.price;
      }
    }
    if (this.body.hasOwnProperty("couponid")) {
      this.discountcode = this.body.couponid;
      // Add discount context
      this.footerarray.push(
        "A discount code is only applied to the first prescription and not the subsequent subscriptions."
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.discount-btn {
  display: inline-block;
  font-weight: 400;
  color: #4f4f4f;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #cdfcb1;
  background-color: #b293ff;
  border-color: #b293ff;
  text-decoration: none;
}

.badge-tertiary {
  background-color: $tertiary;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid #edf2f9;
}

.collapsed .when-opened,
.not-collapsed .when-closed {
  display: none;
}

.collapse.show,
.not-collapsed {
  background-color: #f9f9f9;
}

.payment-option {
  padding: 8px 12px;
  outline: none;
  cursor: pointer;

  @include media-breakpoint-up(xxl) {
    padding: 8px * $scale-font 12px * $scale-font;
  }

  .payment-title {
    padding-left: 13px;

    @include media-breakpoint-up(xxl) {
      padding-left: 13px * $scale-font;
    }
  }
}

img {
  margin-left: 5px;
  width: 35px;
  @include media-breakpoint-down(md) {
    width: 20px;
  }
}

.checkout-agreement {
  ul {
    list-style-type: none;
    li::marker {
      content: "-";
    }
    li {
      padding-left: 30px;
    }
    padding-left: 5px;
  }
}
</style>
